import React from "react";

function PrivacyMomssi() {
	return (
		<section id="privacy">
			<h1>개인정보 처리방침</h1>
			<div className="row">
				<p>
					따보 주식회사는 (이하 ‘회사’는) 개인정보 보호법 제30조에 따라
					정보주체(고객)의 개인정보를 보호하고 이와 관련한 고충을 신속하고
					원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을
					수립․공개합니다.
				</p>
				<h3>1. 개인정보의 처리목적</h3>
				<p>
					회사는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 이외의
					용도로는 이용하지 않습니다.
				</p>
				<li>
					고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별․인증,
					회원자격 유지․관리, 물품 또는 서비스 공급에 따른 금액 결제, 물품 또는
					서비스의 공급•배송 등
				</li>
				<h3>2. 개인정보의 처리 및 보유기간</h3>
				<p>
					① 회사는 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보
					보유․이용기간 또는 법령에 따른 개인정보 보유․이용기간 내에서
					개인정보를 처리․보유합니다.
				</p>
				<p> ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
				<li>
					고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만
					채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
				</li>
				<li>
					전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년
				</li>
				<h3>3. 개인정보의 제3자 제공</h3>
				<p>
					회사는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법
					제17조에 해당하는 경우 외에는 개인정보를 제3자에게 제공하지 않습니다.
				</p>
				<h3>4. 수집한 개인정보의 위탁</h3>
				<li>
					회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지
					않습니다. 향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에
					대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
				</li>
				<h3>5. 정보주체와 법정대리인의 권리․의무 및 행사방법</h3>
				<p>
					정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를
					행사할 수 있습니다.
				</p>
				<p>1. 개인정보 열람요구</p>
				<p>2. 오류 등이 있을 경우 정정 요구</p>
				<p>3. 삭제요구</p>
				<p>4. 처리정지 요구</p>만 14세미만의 아동의 경우, 법정대리인이 아동의
				개인정보를 조회하거나 수정할 권리, 수집 및 이용동의를 철회 할 권리를
				가집니다.
				<h3>6. 처리하는 개인정보 항목</h3>
				<p>
					<strong>[필수 항목]</strong>
				</p>
				<p>
					1. 본인정보(성명, 암호화된 동일인 식별정보, 성별, 생년월일,
					출산여부(임신중/출산), 출산예정일자(또는 출산일자), E-mail주소, ID,
					비밀번호)
				</p>
				<p>2. 모바일 단말정보(운영체제 종류, 운영체제 버전, 기기명)</p>
				<p>
					3. 자녀 정보(태명 또는 자녀명), 출생예정일(또는 출생일), 성별(출생전
					자녀는 성별정보 미입력)
				</p>
				<p>
					4. 본 서비스와 관련한 고객의 신청ㆍ거래ㆍ배송정보와 계약의
					체결ㆍ유지ㆍ이행ㆍ관리 등과 관련하여 본인이 제공한 정보 및 컨텐츠(사진
					이미지, 주소 등)
				</p>
				<p>
					5. 최대한 개인정보를 사용하지 않으려고 노력하고 있습니다. 예를 들면,
					사용자 이름 대신 별명을 사용하는 것이 가능하고 이메일 주소 등 대부분
					입력하지 않아도 사용하는데 문제가 없도록 하였습니다. 또, 아기의 발달
					정보는 명시적으로 공유하지 않으면 공유되지 않습니다.
				</p>
				<br />
				<p>
					<strong>[선택 항목]</strong>
				</p>
				<p>1. 주소</p>
				<li> 마케팅 또는 맞춤정보, 배송정보를 위한 선택 항목입니다.</li>
				<p>2. 휴대폰번호</p>
				<li>본인 확인 또는 상담, 맞춤정보, 배송정보를 위한 선택 항목입니다.</li>
				<p>3. 이메일주소</p>
				<li>본인 확인 또는 상담, 맞춤정보, 배송정보를 위한 선택 항목입니다.</li>
				<p>
					<br />
					<strong>[개인정보 수집방법]</strong>
				</p>
				<p>1. 홈페이지, 모바일앱을 통한 회원가입과 서비스 신청</p>
				<h3>7. 개인정보의 파기</h3>
				<p>
					① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
					불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
				</p>
				<p>② 회사는 다음의 방법으로 개인정보를 파기합니다.</p>
				<li>전자적 파일 : 파일 삭제, 디스크 포맷</li>
				<li>종이 문서 : 분쇄하거나 소각</li>
				<h3>8. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항</h3>
				<p>
					① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
					저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
				</p>
				<p>
					② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
					브라우저에게 보내는 소량의 정보이며 이용자의 PC 컴퓨터내의
					하드디스크에 저장되기도 합니다.
				</p>
				<p>
					가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
					방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
					최적화된 정보 제공을 위해 사용됩니다.
				</p>
				<p>
					나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
					옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
					있습니다.
				</p>
				<p>
					다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
					있습니다.
				</p>
				<h3>9. 개인정보 보호책임자</h3>
				<p>
					회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와
					관련한 정보주체의 불만처리 및 피해구제를 처리하기 위하여 아래와 같이
					개인정보 보호책임자를 지정하고 있습니다.
				</p>
				개인정보 보호책임자
				<li>성명 : 이상용</li>
				<li>연락처 : sy@ddabo.com</li>
				<h3>10. 개인정보 처리방침 변경</h3>이 개인정보 처리방침은 2019. 10.
				14.부터 적용됩니다.
			</div>
		</section>
	);
}

export default PrivacyMomssi;
