import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
	render() {
		if (!this.props.data) return null;
		const logo = "images/" + this.props.data.logo;
		const networks = this.props.data.social.map(function (network) {
			return (
				<li key={network.name}>
					<a href={network.url}>
						<i className={network.className}></i>
					</a>
				</li>
			);
		});

		return (
			<footer>
				<div className="row">
					<Fade bottom>
						<div className="twelve columns">
							<img
								className="logo"
								src={logo}
								alt="따보 회사 로고"
								style={{ width: "140px" }}
							/>
							<ul className="social-links">{networks}</ul>

							<ul className="copyright">
								<li>
									Copyright 2016 - {new Date().getFullYear()} DDABO Inc. all
									rights reserved.
								</li>
							</ul>
						</div>
					</Fade>

					<div id="go-top">
						<a className="smoothscroll" title="Back to Top" href="#home">
							<i className="icon-up-open"></i>
						</a>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
