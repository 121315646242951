import React from "react";
import { useHistory } from "react-router-dom";

function SteamCourse() {
	window.location.replace("https://steam.ddabo.com/steam");
	console.log("111");

	return (
		<section id="steamcourse">
			<h1>생활속의 스팀교육 안내</h1>
			<div className="row">
				<p></p>
			</div>
		</section>
	);
}

export default SteamCourse;
