import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import FooterSimple from "./Components/FooterSimple";

import About from "./Components/About";
import Services from "./Components/Services";
import Steam from "./Components/Steam";
import SteamCourse from "./Components/SteamCourse";
import Privacy from "./Components/Privacy";
import PrivacyMomssi from "./Components/PrivacyMomssi";
import NoMatch from "./Components/NoMatch";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
} from "react-router-dom";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
		};

		ReactGA.initialize("UA-84832536-1");
		ReactGA.pageview(window.location.pathname);
	}

	getData() {
		$.ajax({
			url: "./data.json",
			dataType: "json",
			cache: false,
			success: function (data) {
				this.setState({ data: data });
			}.bind(this),
			error: function (xhr, status, err) {
				console.log(err);
				alert(err);
			},
		});
	}

	componentDidMount() {
		this.getData();
	}

	render() {
		return (
			<Router>
				<div>
					<Switch>
						<Route exact path="/">
							<div className="App">
								<Header data={this.state.data.main} />

								<Services data={this.state.data.services} />
								<Steam data={this.state.data.steam} />
								{/* <Contact data={this.state.data.main} /> */}
								<About data={this.state.data.main} />
								<Footer data={this.state.data.main} />
							</div>
						</Route>
						<Route exact path="/privacy">
							<div className="App">
								<Privacy />
								<FooterSimple data={this.state.data.main} />
							</div>
						</Route>
						<Route exact path="/steam">
							<SteamCourse />
						</Route>
						<Route exact path="/momssi_privacy_policy/">
							<div className="App">
								<PrivacyMomssi />
								<FooterSimple data={this.state.data.main} />
							</div>
						</Route>
						<Route path="*">
							<NoMatch />
						</Route>
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
