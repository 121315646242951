import React, { useState } from "react";
import Zmage from "react-zmage";
import Fade from "react-reveal";
import SwiperCore, {
	Virtual,
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Steam(props) {
	const [showSteamCourse, setShowSteamCourse] = useState("");

	const onClickCourseHandler = (course) => {
		let currentCourse = showSteamCourse;
		if (currentCourse === course) {
			setShowSteamCourse("");
		} else {
			setShowSteamCourse(course);
		}
	};

	if (!props.data) return null;
	const steamPage = "images/" + props.data.steampage;
	const reviews = props.data.reviews.map(function (review) {
		return (
			<SwiperSlide>
				<div style={{ width: "90%", marginBottom: "20px" }}>
					<p>"{review.review}"</p>
				</div>
			</SwiperSlide>
		);
	});

	return (
		<section id="steam">
			<Fade left duration={1000} distance="40px">
				<h1>따보 융합교육 서비스</h1>
				<div className="row education">
					<div className="three columns header-col">
						<h1>
							<span>융합교육 서비스</span>
						</h1>
					</div>
					<div className="nine columns main-col">
						<div className="row item">
							<div className="twelve columns">
								<div>
									<div>
										<h2 className="text-center">
											"여러분들이 공감할 수 있는{" "}
											<span style={{ fontWeight: "bold" }}>
												주변현상들을 주제
											</span>
											로 새롭게{" "}
											<span style={{ fontWeight: "bold" }}>문제를 정의</span>
											하고 과학기술 기반에{" "}
											<span style={{ fontWeight: "bold" }}>
												융합적인 사고로 일상의 문제를 해결
											</span>
											하는 과정을 체험하는 교육입니다."
										</h2>
									</div>
									<br />
									<h2>[교육 목표]</h2>
									<li>우리의 일상이 더욱 특별해지면 좋겠습니다.</li>
									<li>
										누구나 나름의 관점으로 문제를 정의하고 더 나은 방법으로
										해결하는데 도움이 되길 바랍니다.
									</li>

									{/* <div style={{ textAlign: "right" }}>
										<a
											href="https://blog.naver.com/moeblog/222180905209"
											target="_blank"
											rel="noreferrer"
										>
											{"<"}출처 : 교육부 블로그{">"}
										</a>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fade>

			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>교육서비스 문의</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<div>
								<div>
									<h2>
										융합교육 콘텐츠 사이트(
										<a
											href="https://steam.ddabo.com"
											target="_blank"
											rel="noreferrer"
										>
											steam.ddabo.com)
										</a>
									</h2>
									<a
										href="https://steam.ddabo.com"
										target="_blank"
										rel="noreferrer"
									>
										<img src={steamPage} alt="맘씨 Steam 웹페이지 캡쳐" />
									</a>
									<br />
									<br />
									<h2>융합 교육 프로그램 운영</h2>

									<div className="full-box text-center">
										<h2>교육문의</h2>
										- email : sy@ddabo.com <br />-{" "}
										<a
											href="http://pf.kakao.com/_axejxfK/chat"
											target="_blank"
											rel="noreferrer"
										>
											카카오톡 상담하기
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>융합교육 주제</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<h2
								onClick={() => onClickCourseHandler("iot")}
								style={{ cursor: "pointer" }}
							>
								[기술활용실습] 생활속에 사물인터넷{" "}
								<span style={{ fontSize: "14px", textDecoration: "underline" }}>
									커리큘럼 보기
								</span>
							</h2>
							{showSteamCourse === "iot" && (
								<div className="full-box">
									<h1>생활속에 사물인터넷</h1>
									<h4>[교육대상]</h4>
									<li>고등학생, 대학생, 일반인</li>
									<h4>[교육목표]</h4>
									<li>생활속에 인공지능과 사물인터넷 서비스 파악하기</li>
									<li>아두이노 실습을 통해 전자회로와 코딩 체험하기</li>
									<li>디자인씽킹을 통해 문제정의 및 솔루션 탐색 체험하기 기</li>
									<h4>[교육일정]</h4>
									<li>1차시 : 인공지능과 사물인터넷</li>

									<li>
										2차시 : 사물인터넷 실습 1 (아두이노&코딩 기초 : 전기에 대한
										이해, 코드로 LED 제어)
									</li>

									<li>
										3차시 : 사물인터넷 실습 2 (센서 활용 : 조도센서, 초음파센서)
									</li>

									<li>
										4차시 : 사물인터넷 실습 3 (인터넷으로 제어하는 LED 만들기)
									</li>

									<li>
										5차시 : 축제 공모전 해커톤 등 멋진 도전의 기회 활용하기
									</li>

									<li>6차시 : 디자인씽킹을 통해 문제정의</li>
								</div>
							)}
							<h2
								onClick={() => onClickCourseHandler("digital")}
								style={{ cursor: "pointer" }}
							>
								[소양교육] 세상은 왜 디지털화 되어 갈까?{" "}
								<span style={{ fontSize: "14px", textDecoration: "underline" }}>
									커리큘럼 보기
								</span>
							</h2>
							{showSteamCourse === "digital" && (
								<div className="full-box">
									<h1>세상은 왜 디지털화 되어 갈까?</h1>
									<h4>[교육대상]</h4>
									<li>고등학생, 대학생, 일반인</li>
									<h4>[교육목표]</h4>
									<li>디지털 트랜스 전환이 가져오는 변화에 대해 이해하기</li>
									<li>세상을 연결하는 사물인터넷 실습을 통해 원리 이해하기</li>
									<li>문제정의부터 해결까지 체험하기</li>
									<h4>[교육일정]</h4>
									<li>1차시 : 왜 세상이 디지털화 되어갈까?</li>

									<li>2차시 : 디지털 전환 시대에 주요기술</li>

									<li>3차시 : MCU의 역할 및 개발도구 소개</li>

									<li>4차시 : MCU 개발언어인 Python 코딩 기초</li>

									<li>5차시 : 나만의 스마트한 조명장치를 만들어 보자</li>

									<li>
										6차시 : 일상의 문제를 정의하고, 정보화 사고로 분석하자
									</li>
								</div>
							)}

							<h2
								onClick={() => onClickCourseHandler("environment")}
								style={{ cursor: "pointer" }}
							>
								[환경문제] 쓰레기를 줄이는 LED 전광판{" "}
								<span style={{ fontSize: "14px", textDecoration: "underline" }}>
									커리큘럼 보기
								</span>
							</h2>
							{showSteamCourse === "environment" && (
								<div className="full-box">
									<h1>재활용이 안된다면 재사용을 최대한 하는 건 어떨까?</h1>
									<h4>[교육대상]</h4>
									<li>고등학생, 대학생, 일반인</li>
									<h4>[교육목표]</h4>
									<li>환경문제 해결을 위한 다양한 실천 사례 리뷰</li>
									<li>우리 주변에서 흔히 발생하는 환경 문제정의</li>
									<li>제로웨이스트 실천사례 체험</li>
									<h4>[교육일정]</h4>
									<li>1차시 : 환경문제 현황과 해결사례 리뷰</li>

									<li>
										2차시 : 디자인씽킹을 통해 환경 관련 공감하는 현상과 문제정의
										실습
									</li>

									<li>3차시 : 제로웨이스트 실천 사례 체험</li>

									<li>
										4차시 : 쓰레기 줄이기를 위한 LED 전광판 프로토타입 만들기
										실습
									</li>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>모듈형 프로그램</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<div>
								<div>
									<h2>
										교육목적과 주제에 따라 단위프로그램들을 적절히 융합하여
										맞춤형 교육프로그램을 제안합니다.
									</h2>
									<h3>1. 디자인씽킹</h3>
									<li>
										우리 주변의 일상을 새로운 관점에서, 관심을 갖고 새롭게
										살펴보기
									</li>

									<li>
										디자인씽킹을 통한 내가 해결하고 싶은 일상의 문제를 정의하기{" "}
									</li>

									<h3>2. 피지컬 컴퓨팅 활용 </h3>
									<p>
										우리의 아이디어를 현실에 구현하기 위한 실용적인 도구 활용법
										익히기
									</p>

									<h4>1) 전자회로 기초</h4>
									<li>전기가 통하는 길 만들기 원리</li>
									<li>브레드보드 활용, 저항, 버튼 활용 체험</li>
									<h4>2) MCU 보드 활용</h4>
									<li>아두이노 활용 기초</li>
									<li>라즈베리파이 피코 활용 기초 </li>
									<h4>3) 코딩 기초</h4>
									<li>아두이노 활용을 위한 C++ 기초 </li>
									<li>라즈베리파이 피코 활용을 위한 Python 기초</li>
									<li>
										Data 유형, 상수/변수의 역할, 제어문을 활용한 코딩 실습
									</li>
									<h4>4) 센서 활용</h4>
									<li>우리 주변의 존재하는 센서의 종류와 역할 탐색 </li>
									<li>센서 작동 원리 학습 </li>
									<li>
										조도센서(밝기측정), 초음파센서(시간을 거리로 환산) 등 활용
										체험{" "}
									</li>
									<h4>5) 네트워크</h4>
									<li>블루투스, Wifi, 5G 등 네트워크 표준 살펴보기 </li>
									<li>사물인터넷 프로토콜 MQTT 활용 사례 살펴보기 </li>
									<li>블루투스 통신을 활용한 Beacon 실습 </li>
									<li>NodeMCU를 활용한 IoT 디바이스 제작 체험하기</li>

									<h3>3. 웹(Web)</h3>
									<li>웹과 인터넷은 같은 의미인가? </li>
									<li>웹브라우저는 어떻게 내가 있는 곳을 알고있나? </li>
									<li>웹의 동작원리 이해하기 </li>
									<li>웹의 구성요소(HTTP, HTML 등)들에 대해 이해하기 </li>
									<li>웹서버 구축을 직접하고 이해하기 </li>

									<h3>4. 인공지능(AI)</h3>
									<li>왜 데이터가 석유만큼 중요한 자원이 될까? </li>
									<li>컴퓨터로 데이터로 어떻게 학습하는가? </li>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>융합교육 이력</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<div>
								<div>
									<h3>
										2017년 ~ 현재 :{" "}
										<a
											href="https://www.sangsangirum.net/"
											target="_blank"
											rel="noreferrer"
										>
											상상이룸센터"
										</a>
										에서 고등학생 대상 IT분야 워크캠프 진행
									</h3>
									<h3>2022년</h3>
									<li>
										고교학점제를 준비하는 신산업 진로체험 프로젝트에서
										'디지털전환 시대의 사물인터넷' 주제로 12차시 교육프로그램
										진행
									</li>
									<h3>2021년</h3>
									<li>
										"예술이 코딩을 만나다" 주제로 중학생 대상,
										반포잠원교육지원센터에서 진행
									</li>
									<h3>2020년</h3>
									<li>
										"웹,앱 프로토타입과 외주업체와의 소통! Figma로 끝내주마!"
										주제로 소셜캠퍼스온에서 진행
									</li>
									<h3>2017년</h3>
									<li>"부산ITCT수도권마케팅지원센터" 사물인터넷 주제 강의</li>
									<h3>2016년</h3>
									<li>노원구 고등학생 직업체험 교육</li>
									<li>
										"생활속에 아두이노 활용하기" 주제로 대학생&일반인 대상 교육
										진행
									</li>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>교육생들의 평가</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<div>
								<div>
									<Swiper
										spaceBetween={30}
										centeredSlides={true}
										autoplay={{
											delay: 2500,
											disableOnInteraction: false,
										}}
										pagination={{
											clickable: true,
										}}
										navigation={false}
										className="mySwiper"
									>
										{reviews}
									</Swiper>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row education">
				<div className="three columns header-col">
					<h1>
						<span>프로젝트 사례</span>
					</h1>
				</div>
				<div className="nine columns main-col">
					<div className="row item">
						<div className="twelve columns">
							<div>
								<div>
									<p>
										- 펀치 : 학생들과 함께 2019 스마트디바이스톤 최우수상 :
										<a
											href="https://m.blog.naver.com/mtmag/221717718913"
											target="_blank"
											rel="noreferrer"
										>
											보도자료
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Steam;
