import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
	render() {
		if (!this.props.data) return null;

		const logo = "images/" + this.props.data.logo;

		const name = this.props.data.name;
		const description = this.props.data.description;

		return (
			<header id="home">
				<ParticlesBg type="circle" bg={true} num={20} />
				<nav id="nav-wrap">
					<a className="mobile-btn" href="#nav-wrap" title="Show navigation">
						Show navigation
					</a>
					<a className="mobile-btn" href="#home" title="Hide navigation">
						Hide navigation
					</a>

					<ul id="nav" className="nav">
						<li className="current">
							<a className="smoothscroll" href="#home">
								홈
							</a>
						</li>

						<li>
							<a className="smoothscroll" href="#services">
								사회혁신 서비스
							</a>
						</li>

						<li>
							<a className="smoothscroll" href="#steam">
								융합교육 서비스
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#about">
								회사소개
							</a>
						</li>
					</ul>
				</nav>

				<div className="row banner">
					<div className="banner-text text-center">
						<Fade bottom>
							<img className="logo" src={logo} alt="따보 회사 로고" />
							<h1 className="responsive-headline">{name}</h1>
						</Fade>
						{/* <Fade bottom duration={1200}>
							<h3 className="font-test">{description}.</h3>
						</Fade> */}
						<div
							style={{
								backgroundColor: "rgba(255, 255, 255, 0.85)",
								padding: "20px",
								borderRadius: "20px",
								textAlign: "start",
								color: "black",
							}}
						>
							<h2
								style={{
									textAlign: "center",
									marginTop: "10px",
									marginBottom: "25px",
								}}
							>
								{" "}
								따보 주식회사 폐업 안내
							</h2>
							<p style={{ marginBottom: "10px" }}>
								육아휴직을 계기로, 육아환경 개선을 위해 창업한 따보 주식회사가{" "}
								<span style={{ fontWeight: "700" }}>
									2023년 5월 17일자로 운영이 종료될 예정
								</span>
								입니다.
								<br />
								따보에서 운영했던 '육아비서 맘씨'와 '보이스 뿜뿜'도 함께
								종료됩니다.
							</p>

							<p style={{ marginBottom: "10px" }}>
								{" "}
								따보 주식회사는 여기서 마침표를 찍지만 따보의 경험을 바탕으로 더
								나은 사회를 위해 우리의 주변을 살펴보고, 우리가 풀어야할 문제를
								발견하고 함께 해결하도록 노력하겠습니다.
							</p>
							<p style={{ marginBottom: "10px" }}>
								그 동안 따보에서 제공한 서비스를 이용해 주시고, 좋은 의견과 함께
								힘이 되어주셨던 모든 분들께 감사드립니다.
							</p>
							<p style={{ textAlign: "end", marginBottom: "0px" }}>
								<span style={{ fontWeight: "700" }}>
									따보 주식회사 대표 이상용
								</span>
							</p>
						</div>
						<hr />
						{/* <Fade bottom duration={2000}>
							<ul className="social">
								<a href="/#services" className="button btn project-btn">
									<i className="fa fa-heart"></i>사회혁신 서비스
								</a>
								<a href="/#steam" className="button btn github-btn">
									<i className="fa fa-book"></i>융합교육 서비스
								</a>
							</ul>
						</Fade> */}
					</div>
				</div>

				<p className="scrolldown">
					<a className="smoothscroll" href="#services">
						<i className="icon-down-circle"></i>
					</a>
				</p>
			</header>
		);
	}
}

export default Header;
