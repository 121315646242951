import React from "react";

function NoMatch() {
	return (
		<section id="no-match">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
					width: "100vw",
				}}
			>
				<div className="text-center">
					<h1>페이지를 찾을 수 없습니다.</h1>

					<h1>404 Error</h1>
				</div>
			</div>
		</section>
	);
}

export default NoMatch;
