import React from "react";
import Fade from "react-reveal";

function FooterSimple(props) {
	if (!props.data) return null;
	console.log("데이터 확인 : ", props.data);
	const logo = "images/" + props.data.logo;
	const networks = props.data.social.map(function (network) {
		return (
			<li key={network.name}>
				<a href={network.url}>
					<i className={network.className}></i>
				</a>
			</li>
		);
	});

	return (
		<footer>
			<div className="row">
				<Fade bottom>
					<div className="twelve columns">
						<img
							className="logo"
							src={logo}
							alt="따보 회사 로고"
							style={{ width: "140px" }}
						/>
						<ul className="social-links">{networks}</ul>

						<ul className="copyright">
							<li>
								Copyright 2016 - {new Date().getFullYear()} DDABO Inc. all
								rights reserved.
							</li>
						</ul>
					</div>
				</Fade>
			</div>
		</footer>
	);
}

export default FooterSimple;
