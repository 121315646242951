import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
	render() {
		if (!this.props.data) return null;

		const name = this.props.data.name;
		const logo = "images/" + this.props.data.logo;
		const bio = this.props.data.bio;
		const mission = this.props.data.mission;
		const address = this.props.data.address;
		const phone = this.props.data.phone;
		const email = this.props.data.email;
		const ddaboDownload = this.props.data.ddabodownload;

		return (
			<section id="about">
				<Fade duration={1000}>
					<div className="row">
						<div className="three columns">
							<img className="logo" src={logo} alt="따보 회사 로고" />
						</div>
						<div className="nine columns main-col">
							<h2>이웃같은 회사 따보</h2>

							<p>{bio}</p>

							<h2>소셜미션</h2>

							<p>{mission}</p>

							<h2>연혁</h2>
							<h3>2022년</h3>
							<li>
								ECO AGAIN 창작터 메이커톤에서 "X배너용 전광판"으로 우수상 수상
							</li>
							<li>보이스 공유 플랫폼 "보이스뿜뿜" 런칭</li>
							<h3>2020년</h3>

							<li>긴급사업개발비 선정</li>
							<li>강아지 비문(코 패턴)식별엔진 외주개발</li>

							<h3>2019년</h3>
							<li>말로 쓰는 육아일지 “맘씨” 런칭</li>

							<h3>2018년</h3>
							<li>고용노동부 예비사회적기업 선정</li>
							<li>사업개발비지원 사업 선정</li>
							<li>음성인식 성장발달백분위 계산기 런칭</li>

							<h3>2017년</h3>
							<li>사회적기업가 육성사업 선정</li>

							<h3>2016년</h3>
							<li>따보 주식회사 설립</li>
							<li>
								<a
									href="https://www.seoulfn.com/news/articleView.html?idxno=265205"
									target="_blank"
									rel="noreferrer"
								>
									{" "}
									SK텔레콤 음성인식 기반 인공지능 NUGU 개발 공모전 우수상
								</a>
							</li>
							<br />

							<div className="row">
								<div className="columns contact-details">
									<h2>Contact</h2>
									<p className="address">
										<span>{name}</span>
										<br />
										<span>주소 : {address}</span>
										<br />
										<span>이메일 : {email}</span>
										<br />
										<span>사업자등록번호 : 623-88-00397</span>
										<br />
										<span>통신판매신고번호 : 제2019-서울성동-808호</span>
									</p>
								</div>
								<div className="columns download">
									<p>
										{/* <a href={ddaboDownload} className="button">
											<i className="fa fa-download"></i>회사소개서
										</a> */}
									</p>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</section>
		);
	}
}

export default About;
