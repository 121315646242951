import React, { Component } from "react";
import Slide from "react-reveal";

class Services extends Component {
	getRandomColor() {
		let letters = "0123456789ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	render() {
		if (!this.props.data) return null;
		const momssi = "images/" + this.props.data.momssi;
		const kungkung = "images/" + this.props.data.kungkung;
		const voicebbumbbum = "images/" + this.props.data.voicebbumbbum;

		return (
			<section id="services">
				<Slide left duration={1300}>
					<div className="row work">
						<div className="three columns header-col">
							<h1>
								<span>보이스 뿜뿜</span>
							</h1>
							<img
								src={voicebbumbbum}
								style={{ width: "100px" }}
								alt="보이스 뿜뿜 로고"
							/>
						</div>

						<div className="nine columns main-col">
							<div className="row item">
								<div className="twelve columns">
									<div>
										<div style={{ textAlign: "center" }}>
											<h3>
												소리에 추억을 담다 <br />
												보이지 않아도 더 많은 걸 추억한다 <br />
												보이스 공유 플랫폼 "보이스 뿜뿜"
											</h3>
											<iframe
												width="100%"
												height="300px"
												src="https://www.youtube.com/embed/DhU0cGkzVGw"
												title="YouTube video player"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen
											></iframe>
										</div>
										<br />

										<h1>사용안내</h1>
										<p className="info">
											보이스뿜뿜에 저장된 보이스앨범은 네이버 음성인식 플랫폼
											클로바에서 계정연동을 통해 사용하실 수 있습니다.
											<br />
											AI스피커로 음악, 날씨 확인 뿐만 아니라 보이스뿜뿜을 통해
											여러분의 보이스를 감상하시기 바랍니다.
										</p>
										<h1>음성명령 안내</h1>
										<table>
											<tr>
												<th>구분</th>
												<th>음성명령</th>
											</tr>
											<tr>
												<td>보이스 앨범 재생</td>
												<td>
													클로바! 보이스뿜뿜에서 앨범 들려줘!
													<br />
													2집 앨범 들려줘!
												</td>
											</tr>
											<tr>
												<td>보이스 앨범 확인</td>
												<td>
													클로바! 보이스뿜뿜에서 앨범 정보 확인해줘!
													<br />
													3집 앨범 확인해줘!
												</td>
											</tr>
										</table>

										<br />

										<h1>서비스 링크</h1>
										<p className="info">
											<span>&bull;</span>{" "}
											<a
												href="https://clova.ai/ko/skillstore/skill/com.voicebbubbum.voice.clova"
												target="_blank"
												rel="noreferrer"
											>
												네이버 클로바 스킬스토어 : 보이스 뿜뿜 링크
											</a>
											<br />
											<span>&bull;</span>{" "}
											<a
												href="https://voicebbumbbum.com/"
												target="_blank"
												rel="noreferrer"
											>
												보이스뿜뿜 홈페이지
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Slide>
				<Slide left duration={1300}>
					<div className="row education">
						<div className="three columns header-col">
							<h1>
								<span>육아비서 맘씨</span>
							</h1>
							<img
								src={momssi}
								style={{ width: "100px", height: "100px" }}
								alt="맘씨 서비스 로고"
							/>
						</div>

						<div className="nine columns main-col">
							<div className="row item">
								<div className="twelve columns">
									<div>
										<div style={{ textAlign: "center" }}>
											<h3>
												32만 아기의 부모님에게 <br />
												음성인식과 앱을 제공하여 적절한 수유량과 수면시간을{" "}
												<br />
												①알려주고 ②맞춰주는 <br />
												육아비서 맘씨
											</h3>
											<iframe
												width="100%"
												height="300px"
												src="https://www.youtube.com/embed/ASJzyEmasn8"
												title="YouTube video player"
												frameborder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowfullscreen
											></iframe>
										</div>
										<br />

										<h1>사용안내</h1>
										<p className="info">
											육아비서 맘씨는 네이버에 음성인식 플랫폼 클로바에서
											사용하실 수 있습니다. 아이정보 등록과 육아일지 확인을
											위해서 전용 앱 설치도 필요합니다.
										</p>
										<h1>음성명령 안내</h1>
										<table>
											<tr>
												<th>구분</th>
												<th>기록할 때</th>
												<th>확인할 때</th>
											</tr>
											<tr>
												<td>수유</td>
												<td>
													우리아기 분유 100미리 먹었어
													<br />
													둘째아기 모유 150미리 기록해줘
												</td>
												<td>
													수유정보 확인해줘
													<br />
													둘째아기 수유정보 확인해줘
												</td>
											</tr>
											<tr>
												<td>배변</td>
												<td>응가했어</td>
												<td>배변정보 확인해줘</td>
											</tr>
											<tr>
												<td>수면</td>
												<td>우리아기 낮잠잤어</td>
												<td>수면정보 확인해줘</td>
											</tr>
											<tr>
												<td>기타</td>
												<td>
													아동수당이 뭐야? <br />
													B형간염 예방주사 어떻게 해? <br />
													결핵이 뭐야?
												</td>
												<td>-</td>
											</tr>
										</table>

										<br />

										<h1>서비스 링크</h1>
										<p className="info">
											<span>&bull;</span>{" "}
											<a
												href="https://clova.ai/ko/skillstore/skill/com.ddabo.voiceapp.clova.momssi"
												target="_blank"
												rel="noreferrer"
											>
												네이버 클로바 스킬스토어 : 육아비서 맘씨 링크
											</a>
											<br />
											<span>&bull;</span>{" "}
											<a
												href="https://play.google.com/store/apps/details?id=com.ddabo.apps.momssi2019"
												target="_blank"
												rel="noreferrer"
											>
												구글 플레이 : 육아비서 맘씨 링크
											</a>
											<br />
											<span>&bull;</span>{" "}
											<a
												href="http://momc.kr/"
												target="_blank"
												rel="noreferrer"
											>
												맘씨 홈페이지
											</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Slide>

				{/* <Slide left duration={1300}>
					<div className="row work">
						<div className="three columns header-col">
							<h1>
								<span>층간소음 알림이 쿵쿵</span>
							</h1>
							<img src={kungkung} style={{ width: "100px" }} alt="쿵쿵 로고" />
						</div>

						<div className="nine columns main-col">
							<div style={{ textAlign: "center" }}>
								<h3>
									층간소음으로 인한 이웃간의 갈등을 최소화하고,
									<br />
									서로의 감정이 상하기 않기 위해 고민했습니다. <br />
									그리고 서로의 불만을 해소하기 위해 제안합니다. <br />
									층간소음 알리미 쿵쿵
								</h3>
								10월 런칭 예정
							</div>
						</div>
					</div>
				</Slide> */}
			</section>
		);
	}
}

export default Services;
